import moment from 'moment';
class HandlingStorage {
    storage;
    prefix;
    constructor(prefix) {
        this.storage = localStorage;
        this.prefix = prefix || '__hs__:';
    }
    local() {
        const newStorage = new HandlingStorage(this.prefix);
        newStorage.storage = localStorage;
        return newStorage;
    }
    session() {
        const newStorage = new HandlingStorage(this.prefix);
        newStorage.storage = sessionStorage;
        return newStorage;
    }
    set(key, value, lifetimeInMinute) {
        this.storage.setItem(`${this.prefix}${key}`, btoa(JSON.stringify({
            setAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            value: value,
            expireAt: lifetimeInMinute ? moment().add(lifetimeInMinute, 'minutes').format('YYYY-MM-DD HH:mm:ss') : null,
        })));
        return this;
    }
    get(key) {
        const stored = this.storage.getItem(`${this.prefix}${key}`);
        if (!stored) {
            return null;
        }
        const parsed = JSON.parse(atob(stored));
        if (moment(parsed.expireAt).isBefore(moment())) {
            this.storage.removeItem(`${this.prefix}${key}`);
            return null;
        }
        return parsed.value;
    }
    clearExpired() {
        for (let index = 0; index < this.storage.length; index++) {
            const key = this.storage.key(index);
            if (key?.startsWith(this.prefix)) {
                this.get(key);
            }
        }
        return this;
    }
}
export { HandlingStorage };
