import Kalidator from '@owneul/kalidator';
class Process {
    data;
    validator;
    method;
    loaderOnProcess;
    useCustomCatcher;
    customCatcher;
    constructor(data, rules, messages) {
        this.data = data;
        this.validator = new Kalidator(data, rules, messages);
        this.method = 'post';
        this.loaderOnProcess = true;
        this.useCustomCatcher = false;
    }
    post() {
        this.method = 'post';
        return this;
    }
    get() {
        this.method = 'get';
        return this;
    }
    delete() {
        this.method = 'delete';
        return this;
    }
    put() {
        this.method = 'put';
        return this;
    }
    patch() {
        this.method = 'patch';
        return this;
    }
    option() {
        this.method = 'option';
        return this;
    }
    withoutLoading() {
        this.loaderOnProcess = false;
        return this;
    }
    catcher(customCatcher) {
        this.useCustomCatcher = true;
        this.customCatcher = customCatcher;
        return this;
    }
    async go(endpoint, configMessage) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.validator.run();
            }
            catch (error) {
                if (this.useCustomCatcher) {
                    this.customCatcher(error);
                    return reject(error);
                }
                window.$catch(error);
                return reject(error);
            }
            if (configMessage != undefined) {
                window.mm.bom.confirm(configMessage, (isConfirm) => {
                    if (!isConfirm)
                        return;
                    if (this.loaderOnProcess) {
                        window.mm.loading.show();
                    }
                    window.axios[this.method](endpoint, this.data)
                        .then(arg => {
                        return resolve(arg);
                    })
                        .catch(err => {
                        window.mm.loading.hide();
                        if (this.useCustomCatcher) {
                            this.customCatcher(err);
                            return reject(err);
                        }
                        window.$catch(err);
                        return reject(err);
                    })
                        .finally(window.mm.loading.hide);
                });
            }
            else {
                if (this.loaderOnProcess) {
                    window.mm.loading.show();
                }
                window.axios[this.method](endpoint, this.data)
                    .then(arg => {
                    return resolve(arg);
                })
                    .catch(err => {
                    window.mm.loading.hide();
                    if (this.useCustomCatcher) {
                        this.customCatcher(err);
                        return reject(err);
                    }
                    window.$catch(err);
                    return reject(err);
                })
                    .finally(window.mm.loading.hide);
            }
        });
    }
}
export { Process };
